<template>
  <b-card title="Cities">
    <b-tabs>
      <b-tab
        :active="currentRouteName=='add-city'"
        @click="$router.push('/add-city')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add City</span>
        </template>

      </b-tab>
      <b-tab
        :active="currentRouteName=='cities-list'"
        @click="$router.push('/cities-list')"
      >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Cities</span>
        </template>

        <!-- <CitiesList ref="allData"></CitiesList> -->
      </b-tab>

      <b-tab
        :active="currentRouteName=='manage-location'"
        @click="$router.push('/manage-location')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Manage Location</span>
        </template>

        <!-- <ManageLocation @manage-location="getData"></ManageLocation> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='location-list'"
        @click="$router.push('/locations-list')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>All Location</span>
        </template>

        <!-- <ViewLocation ref="location"></ViewLocation> -->
      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <AddCity v-if="route.name === 'add-city' " />
      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import AddCity from './AddCity.vue'
import CitiesList from './CitiesList.vue'
import ManageLocation from './ManageLocation.vue'
import ViewLocation from './ViewLocation.vue'

export default {
  components: {
    BCardCode,
    ManageLocation,
    BCard,
    BCardText,
    BTabs,
    BTab,
    AddCity,
    CitiesList,
    ViewLocation,
  },
  setup() {
    const allData = ref()
    const location = ref()

    const getData = () => {
      allData.value.refetchData()
      location.value.refetchData()
    }
    return {
      getData,
      location,
      allData,

    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>
